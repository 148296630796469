<template>
  <div>
    <CDataTable
      class="notification-config border rounded"
      :items="channelListOpt"
      :fields="channelFields"
      :items-per-page="10"
      pagination
    >
      <template #action_type="{ item }">
        <td>
          <div class="px-5 text-left">
            <p>{{ item.action_type }}</p>
          </div>
        </td>
      </template>
      <template #email="{ item }">
        <td>
          <div class="p-1 text-center custom-checkbox">
            <input
              type="checkbox"
              :name="`email_${item.channel_type_id}`"
              :checked="item.email"
              @click="handleChangeCheck(item, 'email', $event.target.checked)"
            />
          </div>
        </td>
      </template>
      <template #whatsapp="{ item }">
        <td>
          <div class="p-1 text-center custom-checkbox">
            <input
              type="checkbox"
              :name="`whatsapp_${item.channel_type_id}`"
              :checked="item.whatsapp"
              @click="
                handleChangeCheck(item, 'whatsapp', $event.target.checked)
              "
            />
          </div>
        </td>
      </template>
      <template #sms="{ item }">
        <td>
          <div class="p-1 text-center custom-checkbox">
            <input
              type="checkbox"
              :name="`sms_${item.channel_type_id}`"
              :checked="item.sms"
              @click="
                handleChangeCheck(item, 'sms', $event.target.checked)
              "
            />
          </div>
        </td>
      </template>
      <template #no-items-view>
        <ul class="list-group list-group-flush" colspan="4">
          <li class="my-5 list-style-none">
            <div class="text-center my-5">
              <h5 class="text-center">
                No Data Found
                <i class="fas fa-ban" :style="{ color: '#e55353' }"></i>
              </h5>
            </div>
          </li>
        </ul>
      </template>
    </CDataTable>
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "NotificationChannels",
  component: {},
  data() {
    return {
      channelFields: [
        { key: "action_type", label: "", _style: "width: 30%" },
        { key: "email", label: "Personal Email", _style: "width: 20%" },
        { key: "whatsapp", label: "WhatsApp", _style: "width: 20%" },
        { key: "sms", label: "SMS", _style: "width: 20%" },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "getCommunicationChannel",
      "getCommunicationChannelDetails",
    ]),
    channelListOpt() {
      return (
        this.getCommunicationChannel.map(
          (v) =>
            _.filter(
              this.getCommunicationChannelDetails,
              (o) => o.channel_type_id === v.channel_type_id
            ).map((s) => ({
              detail_id: s?.detail_id,
              channel_type_id: s?.channel_type_id,
              action_type: s?.channel_type?.action_type,
              email: s?.email,
              whatsapp: s?.whatsapp,
              sms: s?.sms,
            }))[0] || {
              channel_type_id: v?.channel_type_id,
              action_type: v?.action_type,
              email: v?.email,
              whatsapp: v?.whatsapp,
              sms: v?.sms,
            }
        ) || []
      );
    },
  },
  methods: {
    ...mapActions([
      "showToast",
      "createCommunicationChannelDetails",
      "fetchCommunicationChannelDetails",
      "updateCommunicationChannelDetails",
    ]),
    handleChangeCheck(item, name, value) {
      Vue.set(item, name, value);
      if (
        this.getCommunicationChannelDetails.some(
          (e) => e.channel_type_id === item.channel_type_id
        )
      ) {
        this.updateCommunicationChannelDetails({
          detail_id: item.detail_id,
          payload: item,
        });
      } else {
        let finalPayload = {
          channel_type_id: item.channel_type_id,
          email: item.email,
          whatsapp: item.whatsapp,
          sms: item.sms,
        };
        this.createCommunicationChannelDetails(finalPayload).then((res) => {
          this.fetchCommunicationChannelDetails();
        });
      }
    },
  },
};
</script>

<style lang="scss">
.notification-config {
  .table td,
  .table th {
    border-top: none !important;
    text-align: center;
    vertical-align: middle;
    justify-content: center;
  }

  .custom-checkbox {
    input[type="checkbox"] {
      margin-top: -30px;
    }

    input[type="checkbox"] {
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeSpeed;
      width: 14px;
      height: 14px;
      margin: 0 1px 0 0;
      position: relative;
      cursor: pointer;

      &:after {
        content: "";
        vertical-align: middle;
        text-align: center;
        line-height: 13px;
        position: absolute;
        cursor: pointer;
        height: 19px;
        width: 19px;
        left: 0px;
        top: 0px;
        font-size: 10px;
        border: solid 2px #ccc;
        box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.1);
        background: #fff;
        border-radius: 3px;
      }

      &:hover:after {
        box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.2);
      }

      &:checked:after {
        border-color: #767676;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAQAAAAngNWGAAAAiUlEQVR4AbXStQECMQBA0V9iS+HW4LAmNW6zpMbd4dz9LvriIaKniOBp8QqKCvwya6rAp8PrAC906DvDKw2g7gDfrAWUORnhhpWKtYHilxlgkTjDb+pGFyhwNF/MnNSbjt6sB+S/zAx+aZIEBSDLwX57Zm8KmTdz3McpVfauN9w39HEpnK5ZJM8Ldd7hI/X/HugAAAAASUVORK5CYII=");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 20px;
      }
    }
  }
}
</style>
