var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.isFetchingItem)?_c('LoadingOverlay'):_vm._e(),_c('CCard',[_c('CCardBody',{staticClass:"m-4"},[_c('CRow',{staticClass:"justify-content-between"},[_c('CCol',{attrs:{"md":"12","sm":"12","lg":"4"}},[_c('ValidationObserver',{ref:"uploadform",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{attrs:{"name":"uploadform"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('CRow',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('label',{staticClass:"font-weight-bolder required"},[_vm._v(" Personal Email ")]),_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('TextInput',{attrs:{"name":"personal_email","value":_vm.notify_config.personal_email,"error":errors[0]},on:{"input":_vm.handleChange}})]}}],null,true)})],1)]),_c('CRow',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('label',{staticClass:"font-weight-bolder required"},[_vm._v(" WhatsApp ")]),_c('ValidationProvider',{ref:"whatsapp",attrs:{"rules":{
                      phone: /^\+\d{1,3}\^\d{1,15}$/,
                      required: true,
                    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('PhoneInputwithCountry',{ref:"whatsappNo",attrs:{"name":"whatsapp","value":_vm.notify_config.whatsapp,"options":_vm.options && _vm.options['dialingCode']
                          ? _vm.options['dialingCode']
                          : [],"error":errors[0]},on:{"change":_vm.handleChange}})]}}],null,true)})],1)]),_c('CRow',{staticClass:"row mb-5"},[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('label',{staticClass:"font-weight-bolder required"},[_vm._v(" SMS ")]),_c('ValidationProvider',{ref:"sms",attrs:{"rules":{
                      phone: /^\+\d{1,3}\^\d{1,15}$/,
                      required: true,
                    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('PhoneInputwithCountry',{ref:"smsNo",attrs:{"name":"sms","value":_vm.notify_config.sms,"options":_vm.options && _vm.options['dialingCode']
                          ? _vm.options['dialingCode']
                          : [],"error":errors[0]},on:{"change":_vm.handleChange}})]}}],null,true)})],1)]),_c('CRow',{staticClass:"row mb-3"},[_c('div',{staticClass:"d-flex justify-content-end col-lg-12 col-md-12 col-sm-12"},[_c('CButton',{staticClass:"mx-1",attrs:{"type":"button","color":"primary","variant":"outline","disabled":_vm.isSubmitting},on:{"click":function($event){_vm.payload = {}}}},[_vm._v("Cancel")]),_c('CButton',{staticClass:"mx-1",attrs:{"type":"button","color":"primary","disabled":_vm.isSubmitting},on:{"click":_vm.onSubmit}},[_vm._v("Submit")])],1)])],1)]}}])})],1),_c('CCol',{attrs:{"md":"12","sm":"12","lg":"7"}},[_c('div',{staticClass:"w-100 text-center"},[_c('inline-svg',{attrs:{"src":_vm.placeholder,"title":"placeholder","width":"80%"}})],1)])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }