<template>
  <div>
    <LoadingOverlay v-if="!isFetchingItem" />
    <CCard>
      <CCardBody class="m-4">
        <CRow class="justify-content-between">
          <CCol md="12" sm="12" lg="4">
            <ValidationObserver ref="uploadform" v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(onSubmit)" name="uploadform">
                <CRow class="row mb-3">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <label class="font-weight-bolder required">
                      Personal Email
                    </label>
                    <ValidationProvider
                      rules="required|email"
                      v-slot="{ errors }"
                    >
                      <TextInput
                        name="personal_email"
                        :value="notify_config.personal_email"
                        @input="handleChange"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
                <CRow class="row mb-3">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <label class="font-weight-bolder required">
                      WhatsApp
                    </label>
                    <ValidationProvider
                      ref="whatsapp"
                      :rules="{
                        phone: /^\+\d{1,3}\^\d{1,15}$/,
                        required: true,
                      }"
                      v-slot="{ errors }"
                    >
                      <PhoneInputwithCountry
                        name="whatsapp"
                        :value="notify_config.whatsapp"
                        :options="
                          options && options['dialingCode']
                            ? options['dialingCode']
                            : []
                        "
                        @change="handleChange"
                        :error="errors[0]"
                        ref="whatsappNo"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
                <CRow class="row mb-5">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <label class="font-weight-bolder required"> SMS </label>
                    <ValidationProvider
                      ref="sms"
                      :rules="{
                        phone: /^\+\d{1,3}\^\d{1,15}$/,
                        required: true,
                      }"
                      v-slot="{ errors }"
                    >
                      <PhoneInputwithCountry
                        name="sms"
                        :value="notify_config.sms"
                        :options="
                          options && options['dialingCode']
                            ? options['dialingCode']
                            : []
                        "
                        @change="handleChange"
                        :error="errors[0]"
                        ref="smsNo"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
                <CRow class="row mb-3">
                  <div
                    class="d-flex justify-content-end col-lg-12 col-md-12 col-sm-12"
                  >
                    <CButton
                      type="button"
                      color="primary"
                      variant="outline"
                      class="mx-1"
                      @click="payload = {}"
                      :disabled="isSubmitting"
                      >Cancel</CButton
                    >
                    <CButton
                      type="button"
                      color="primary"
                      class="mx-1"
                      @click="onSubmit"
                      :disabled="isSubmitting"
                      >Submit</CButton
                    >
                  </div>
                </CRow>
              </form>
            </ValidationObserver>
          </CCol>
          <CCol md="12" sm="12" lg="7">
            <div class="w-100 text-center">
              <inline-svg :src="placeholder" title="placeholder" width="80%" />
            </div>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import { mapActions, mapGetters, mapMutations } from "vuex";

import TextInput from "@/components/reusable/Fields/TextInput";
import PhoneInputwithCountry from "@/components/reusable/Fields/PhoneInputwithCountry";

import { extend } from "vee-validate";
import { required, email, regex } from "vee-validate/dist/rules";

extend("required", { ...required, message: "This field is required" });
extend("email", { ...email, message: "Invalid email" });
extend("phone", { ...regex, message: "Invalid phone number" });

import placeholder from "../../../../public/img/side-placeholder.svg";

export default {
  name: "NotificationConfig",
  components: {
    TextInput,
    PhoneInputwithCountry,
  },
  data() {
    return {
      notify_config: {},
      payload: {},
      isSubmitting: false,
      placeholder,
    };
  },
  computed: {
    ...mapGetters([
      "getCommunicationChannel",
      "dialingCodeWithCountryCode",
      "getUser",
    ]),
    isFetchingItem() {
      if (this.dialingCodeWithCountryCode) {
        if (Object.keys(this.getUser).length) {
          this.notify_config = {
            personal_email: this.getUser.personal_email || "",
            sms: this.getUser.sms || "",
            whatsapp: this.getUser.whatsapp || "",
          };
        }
      }
      return true;
    },
    options() {
      return {
        dialingCode: this.dialingCodeWithCountryCode || [],
      };
    },
  },
  methods: {
    ...mapActions(["showToast", "updateUser", "locationCountryInfo"]),
    handleChange(name, value) {
      Vue.set(this.notify_config, name, value);
      Vue.set(this.payload, name, value);
    },
    clearFieldErrors(name, hasError) {
      this.$nextTick(() => {
        if (name == hasError.sms) {
          this.$refs.phone.errors.clear;
          this.$refs.phone.reset();
        } else if (name == hasError.whatsapp) {
          this.$refs.whatsapp.errors.clear;
          this.$refs.whatsapp.reset();
        }
      });
    },
    async onSubmit() {
      const isValid = await this.$refs.uploadform.validate();
      const isField = Object.keys(this.payload);
      if (!isValid || isField.length == 0) {
        this.showToast({
          class: "bg-danger text-white",
          message:
            isField.length == 0
              ? "Fields not updated!"
              : "please fill mandatory fields!",
        });
        return;
      }
      this.isSubmitting = true;
      let finalPayload = {
        ...this.payload,
      };
      this.updateUser(finalPayload).then((res) => {
        this.isSubmitting = false;
        this,payload = {}
      });
    },
  },
};
</script>

<style lang="scss">
.notification-config {
  .table td,
  .table th {
    border-top: none !important;
    text-align: center;
    vertical-align: middle;
  }
}
</style>
