<template>
  <div class="container-md col-lg-11 col-md-11 pb-5 mb-2">
    <div>
      <CRow class="pr-0 pl-0">
        <CCol md="12" sm="12" lg="12">
          <h4 class="text-primary main-title mt-2 mb-4">
            Notification Configuration
          </h4>
        </CCol>
      </CRow>
    </div>
    <div>
      <CCard>
        <CCardBody>
          <div>
            <ul class="nav nav-tabs border-0">
              <li class="nav-item">
                <a
                  href="#channel"
                  target="_self"
                  class="nav-link"
                  :class="{ active: currentTab == 'Personal Config' }"
                  @click="currentTab = 'Personal Config'"
                  >Notification Config</a
                >
              </li>
              <li class="nav-item">
                <a
                  href="#channel"
                  target="_self"
                  class="nav-link"
                  :class="{ active: currentTab == 'Notification Channels' }"
                  @click="currentTab = 'Notification Channels'"
                  >Notification Channels</a
                >
              </li>
            </ul>
          </div>

          <div>
            <div class="tab-content">
              <div
                class="tab-pane"
                :class="{ active: currentTab == 'Personal Config' }"
                :style="currentTab == 'Personal Config' ? '' : 'display:none;'"
              >
                <NotificationConfig />
              </div>
              <div
                class="tab-pane"
                :class="{ active: currentTab == 'Notification Channels' }"
                :style="
                  currentTab == 'Notification Channels' ? '' : 'display:none;'
                "
              >
                <NotificationChannels />
              </div>
            </div>
          </div>
          <CRow class="my-4">
            <div
              class="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center"
            >
              <button
                class="col-sm-1 btn rounded-0 btn-outline-primary btn-lg float-left mt-2 ml-2"
                @click="navToDashboard()"
              >
                Home
              </button>
              <button
                @click="goBack()"
                name="next_page"
                class="col-sm-1 btn rounded-0 btn-primary btn-lg mt-2 ml-2"
              >
                Back
              </button>
            </div>
          </CRow>
        </CCardBody>
      </CCard>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";

import NotificationChannels from "@/containers/Communication/Notification/NotificationChannels.vue";
import NotificationConfig from "@/containers/Communication/Notification/NotificationConfig.vue";

export default {
  name: "NotificationConfiguration",
  components: {
    NotificationChannels,
    NotificationConfig
  },
  data() {
    return {
      currentTab: "Personal Config",
    };
  },
  watch: {},
  computed: {
    ...mapGetters(["getCommunicationChannel", "getUser"]),
    channelListOpt() {
      return (
        this.getCommunicationChannel.map((v) => ({
          channel_type_id: v?.channel_type_id,
          action_type: v?.action_type,
          email: v?.email,
          whatsapp: v?.whatsapp,
          sms: v?.sms,
        })) || []
      );
    },
  },
  methods: {
    ...mapActions([
      "showToast",
      "fetchCommunicationChannel",
      "createCommunicationChannelDetails",
      "fetchCommunicationChannelDetails",
      "locationCountryInfo"
    ]),
    navToDashboard() {
      this.$router.push({
        path: "/recruiter-dashboard",
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  mounted() {
    this.fetchCommunicationChannel();
    this.locationCountryInfo();
    this.fetchCommunicationChannelDetails();
  },
  created() {
    window.addEventListener("resize", () => {
      this.$store.commit("SET_WINDOW_WIDTH");
    });
  },
};
</script>

<style lang="scss">
.preview-box {
  padding: 22px 24px 20px;
  background-color: var(--modal-background);
  border-radius: 3px;
  box-shadow: 0 17px 50px 0 rgba(var(--shadow-rgb), 0.19),
    0 12px 15px 0 rgba(var(--shadow-rgb), 0.24);
}
</style>
